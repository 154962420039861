<template>
    <div>
        <div class="header_wrap w-100 d-none d-lg-block" ref="header">
            <div class="topBar-home">
                <div class="d-flex">
                    <div @click="$router.push('/web/dashboard')" class="">
                        <img src="./assets//images//header/logo2.png" width="100px">
                    </div>
                    <div class="mt-2 ml-3" @click="toHome()">
                        <img src="./assets/images/header/home-icon.png" width="35" height="auto">
                    </div>
                    <!-- <div class="input-container ml-4" v-if="$route.path.includes('dashboard')">
                        <input type="text" class="text-18" v-model="searchCategory" @input="ProductList" :placeholder="$t('search')">
                        <b-icon icon="search" scale="1" class="icon"></b-icon>
                    </div> -->
                </div>
        
                <div class="d-flex">
                    <div class="d-flex">
                        <div v-for="(value, name) in walletInfo" :key="name">
                            <div class="wallet-info-header">
                            <!-- <div class="">{{ $t(name) }}</div>  -->
                            <div v-if="name !== 'memberLevel'" class="value mt-3">MYR {{ parseFloat(value).toLocaleString() }}</div>
                            <div v-else class="value">{{ value }}</div>
                        </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <img @click="$router.push('/web/product/cart')" src="./assets/images/product/cart.png"
                            height="35px" width="35px" alt="" class="mr-3">
                        <img @click="$router.push('/web/notification')" src="./assets/images/product/ring.png" height="35px" width="35px" alt="">
                    </div>
                    <div v-b-toggle.sidebar-right>
                        <img src="./assets/images/product/user.png" height="35px" width="35px" class="mt-2 ml-3 mr-3">
                    </div>
                    <div class="mt-2">
                        <b-dropdown id="dropdown-lang" left variant="link" toggle-class="my-dropdown" @show="toggleDropdown" @hide="toggleDropdown">
                            <template #button-content>
                                <span class="text-white text-16 font-weight-bold text-uppercase ml-3">
                                    <span v-if="$i18n.locale == 'zh'">{{ $i18n.locale }}</span>
                                    <span v-else>{{ 'en' }}</span>
                                </span>
                                <i :class="dropdownIcon" aria-hidden="true" class="text-white font-weight-bold ml-2 text-16"></i>
                                <!-- <span class="text-primary text-16 font-weight-bold text-uppercase">{{ $i18n.locale }}</span> -->
                            </template>
                            <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                            <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div> 
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>
            <div class="">
                <div class="topBar-sidebar py-4">
                    <div class="d-flex ml-4 mb-3">
                        <img src="./assets/images/profile/user.png" width="40px" height="40px" class="mt-2">
                        <div class="profile-banner mt-3 text-12">
                            <span class="text-14 font-weight-bold">{{ $root.userInfo.username }}</span>
                            <!-- <div v-if="$root.userInfo.premium_member == 1" class="profile-rankbox">Premium User</div>
                            <div v-else  class="profile-rankbox">Normal User</div> -->
                        </div>
                        
                    </div>
                </div>
                <div class="myorder-bg">
                    <div class="text-secondary text-left font-weight-bold text-16 ml-4">{{ $t('my_orders')}}</div>
                    <div class="myorder-box ml-4">   
                        <div v-for="item in myOrder" :key="item.name" class="myorder-item mt-2" @click="$router.push(item.path)">
                            <img :src="require(`./assets/images/profile/${item.name}.png`)" alt="my-order" class="myorder-icon">
                            <div class="myorder-text font-weight-bold">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div class="text-left" >
                
                    <div class="text-secondary font-weight-bold text-18 mb-3">{{ $t('general')}}</div>
                    <!-- <div v-for="item in bonus_record" :key="item" class="card-me mb-2"
                        @click="$router.push('/web/' + item)"> -->
                    <div v-for="item in bonus_record" :key="item" class="card-me mb-2"
                        >
                        <div class="d-flex justify-content-between">
                            <span>
                                <img :src="require('./assets/images/profile/' + item + '.png')" alt="" height="auto"
                                width="auto">
                                <span class=" text-14 font-weight-600 ml-2">{{ $t(item) }}</span>
                            </span>
                            <span><i class="fa fa-chevron-right mt-2"></i></span>
                        </div>
                    </div>
                    <div class="card-me text-center text-danger mt-3" @click="logout()">{{ $t('logout') }}</div>

                </div>
            </div>
        </b-sidebar>
    </div>   
</template>
<script>
export default {
    computed: {
        walletInfo() {
            const userInfo = this.$root.userInfo || {};
            return {
                // memberLevel: this.$root.userInfo.premium_member == 1 ? 'PREMIUM' : 'NORMAL',
                point3: parseFloat(userInfo.point3) || 0,
                // point1: parseFloat(userInfo.point1) || 0,
            };
        },
        dropdownIcon() {
            return this.isDropdownOpen ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-down';
        }
    },
    data() {
        return {
            show: false,
            isDropdownOpen: false,
            companies: [
            ],
            myOrder: [
                // {name:'payment'},
                // {name:'process'},
                // {name:'confirmation'},
                {name:'Order History', path: '/web/product/orderHistory' },
                {name:'Hotel Booking', path: '/web/hotel/orderHotelHistory'},
            ],
            bonus_record: [
                // "edit_profile",
                // "withdraw_details",
                // "language",
                // "announcement",
                // "security_center",
                "contact_us",   

            ]
        };
    },
    methods: {
        toHome() {
            var token = localStorage.getItem('currencyToken');
            var lang = localStorage.getItem('lan');
            var baseUrl = window.location.hostname === 'mall.jaboclub.com' ? 'https://jaboclub.com/web/' : 'https://uat.jaboclub.com/web/' ;
            
            if (token) {
                var url = `${baseUrl}?key=${encodeURIComponent(token)}&lan=${encodeURIComponent(lang)}`;
                window.location.href = url;
            } else {
                console.error('Token not found in localStorage');
            }
        },
        toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        },
        logout() {
            localStorage.removeItem('currencyToken');
            localStorage.removeItem('info');
            var baseUrl = window.location.hostname === 'mall.jaboclub.com' ? 'https://jaboclub.com/web/' : 'https://uat.jaboclub.com/web/sessions/login' ;
            window.location.href = baseUrl;
        },
    },
}
</script>

<style>
.login{
    background-image: url('./assets/images/bg/bg3.png') !important;
}
</style>
